<template>
    <div v-if="value">
        <md-table v-model="tableData" :table-header-color="tableHeaderColor">
            <md-table-row @click.native="test(item)" slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Team 1" class="name-cell">
                    <div
                        v-if="item.scores[0] && item.scores[1]"
                        style="text-align: right;"
                        :class="getRowClass(1, item)"
                    >
                        {{ item.scores[0].team.name }}
                    </div>
                    <div
                        v-else-if="item.scores[0]"
                        :class="getRowClass(1, item)"
                    >
                        {{ item.scores[0].team.name }}
                    </div>
                </md-table-cell>
                <md-table-cell class="score-cell" style="text-align: right;">
                    <span v-if="item.scores[0]" :class="getRowClass(1, item)" >
                        {{ getScore(item.scores[0].score.score) }}
                    </span>
                </md-table-cell>
                <template v-if="item.scores[1]">
                  <md-table-cell v-if="item.scores[1]" class="score-cell">
                      <span v-if="item.scores[1]" :class="getRowClass(2, item)">
                          {{ getScore(item.scores[1].score.score) }}
                      </span>
                  </md-table-cell>
                  <md-table-cell v-if="item.scores[1]" md-label="Team 2" class="name-cell">
                      <div
                          style="text-align: left"
                          :class="getRowClass(2, item)"
                      >
                          {{ item.scores[1].team.name }}
                      </div>
                  </md-table-cell>
                </template>
            </md-table-row>
        </md-table>
    </div>
</template>

<style scoped>
    .md-field {
        margin: 0 !important;
    }

    .win {
        font-weight: bold;
    }

    .muted {
        color: #ccc;
    }

    .name-cell {
        width: calc(50% - 40px);
    }

    .score-cell {
        width: 40px;
    }
</style>

<script>

function EMPTY_SCORE() {
    return {
        id: null,
        score: null,
    }
}

export default {
    name: "simple-table",
    props: {
        tableHeaderColor: {
            type: String,
            default: "",
        },
        value: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            selected:  [],
        };
    },

    computed: {
        tableData() {
            let result = [];
            for (let game of this.value) {
                let item = {
                    gameId: game.id,
                    scores: [],
                };
                
                for (let team of game.teams) {
                    let score = game.scores.find(s => s.team.id === team.id);
                    if (score) {
                        score.score = Number(score.score);
                    }
                    item.scores.push({
                        id:        game.id,
                        createdAt: game.created_at,
                        team:      { id: team.id, name: team.name },
                        score:     score || EMPTY_SCORE()
                    }); 
                }

                result.push(item);
            }
            return result.sort((a, b) => {
                if (a.created_at < b.created_at) { return -1; }
                if (a.created_at > b.created_at) { return 1; }
                return 0;
            });
        },
    },

    methods: {
        test(item) {
            this.$emit('click:row', item)
        },
        // handleInput(score, gameId, teamId) {
        //     let params = {
        //         boardId: sessionStorage.getItem("boardId"),
        //         score:   score.score,
        //         game:    gameId,
        //         team:    teamId,
        //     }
        //     if (score.id) {
        //         params.id = score.id
        //     }
        //     this.$emit('update-score', params);
        // },
        
        getScore(score) {
            if (score === null) return "-";
            return score;
        },

        getRowClass(team, game) {
          // return "123";
            if (team === undefined || ! game) return 'Nope';
            if (! game.scores[1]) return "win";
            if (! game.scores[0].score.score || ! game.scores[0].score.score) return "muted";
            if (team === 1 && Number(game.scores[0].score.score) > Number(game.scores[1].score.score)) {
                return "win";
            }
            if (team === 2 && Number(game.scores[1].score.score) > Number(game.scores[0].score.score)) {
                return "win";
            }
        }
    }
};
</script>
