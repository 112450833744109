<template>
    <div class="content">
        <div class="md-layout">
            <template v-if="$store.state.leaderboard">

                <div style="width: 50%; padding: 15px">
                    <md-field>
                        <label for="activeRound">Ronde</label>
                        <md-select v-model="round" name="round" id="round">
                            <md-option key="-1" value="">Automatisch</md-option>
                            <md-option v-for="round of $store.state.leaderboard.rounds" :key="round.id" :value="round.id">{{ round.name }}</md-option>
                        </md-select>
                    </md-field>
                </div>

                <div
                    v-for="group of roundGroups"
                    :key="group.id"
                    class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                >
                    <md-card>
                        <md-card-header data-background-color="green" style="display: flex; justify-content: space-between;">
                            <h4 class="title">{{ group.name }}</h4>

                            <md-button class="md-icon-button md-dense md-primary" @click="openEditModal(group)">
                                <md-icon>edit</md-icon>
                            </md-button>
                        </md-card-header>
                        <md-card-content>
                            <simple-table 
                                table-header-color="green" 
                                :value="group.games" 
                                @click:row="openScoreModal"
                            />
                        </md-card-content>
                    </md-card>
                </div>
            </template>
        </div>
        <v-modal
            ref="myModal"
            target="modal-target"
            close-on-backdrop-click
        >
            <w-j-modal :title="modal.title">
                
                <ul style="padding: 0;">

                    <li class="modal-teams">
                        <div style="width: 85%">
                            <v-select
                                v-model="modal.addTeam"
                                :options="modal.allTeams"
                                :taggable="true"
                                :push-tags="true"
                                label="name"
                            />
                            <!--                                @keydown.enter.prevent="addTeamToGroup"-->
                            <!--                                @input="handleTeamInput"-->
                            <!--                                :createOption="createTeamOption"-->
                        </div>
                        <a :class="{ 'disabled': loading }" @click="addTeamToGroup()">
                            <md-icon>add_circle</md-icon>
                        </a>
                    </li>

                    <li v-for="team of modal.teams" :key="team.id" class="modal-teams">
                        <div>{{ team.name }}</div>
                        <a @click="removeTeamFromGroup(team.id)" class="md-text-danger">
                            <md-icon>delete</md-icon>
                        </a>
                    </li>
                </ul>

                <div slot="footer">
                    <div style="text-align: right">
                        <md-button @click="$refs.myModal.close();">Sluiten</md-button>
                    </div>
                </div>
            </w-j-modal>
        </v-modal>

        
        <v-modal
            ref="scoreModal"
            target="modal-target"
            close-on-backdrop-click
        >
            <w-j-modal v-if="scoreModalData" title="Score bewerken">
                
                <div v-for="(entry, index) in scoreModalData.scores" :key="index" class="score__wrapper">
                    <div class="score__name">
                        {{ entry.team.name }}
                    </div>
                    <div class="score__score"> 
                        <md-field>
                            <md-input 
                                ref="modalScore"
                                v-if="(index + 1) < scoreModalData.scores.length" 
                                v-model="entry.score.score"
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                @keydown.enter="focusNext(index)"
                            />
                            <md-input 
                                ref="modalScore"
                                v-else
                                v-model="entry.score.score"
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                @keydown.enter="postScores()"
                            />
                        </md-field>
                    </div>
                </div>

                <div slot="footer">
                    <div style="text-align: right">
                        <template v-if="! scoreModalLoading">
                            <md-button @click="$refs.scoreModal.close();">Sluiten</md-button>
                            <md-button class="md-primary" @click="postScores();" :disabled="submitDisabled">Opslaan</md-button>
                        </template>
                        <md-progress-spinner v-else :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                    </div>
                </div>
            </w-j-modal>
        </v-modal>
    </div>
</template>

<style lang="scss">
    .team-modal {
        min-height: 300px;
    }
    .modal-teams {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
        margin: 5px 0;
    }

    .score {
        &__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        &__name {
            flex: 1;
        }
        &__score {
            width: 75px;

            &::v-deep .md-field {
                margin: 0;

                .md-input {
                    width: 75px;
                    text-align: center;
                }
            }
        }
    }
    .vs__selected {
        background-color: #dddddd !important;
    }
    .disabled {
        opacity: .5;
        cursor: not-allowed;
    }
</style>

<script>
import { SimpleTable } from "@/components";
import { VModal } from "@arfedulov/vue2-modal";
import WJModal from '../components/Modals/WJModal.vue';

export default {
    components: {
        SimpleTable,
        VModal,
        WJModal
    },

    computed: {
        showRound() {
            let visibleRoundId  = this.round || this.$store.state.leaderboard.activeRound.id;
            return this.$store.state.leaderboard.rounds.find(rnd => rnd.id === visibleRoundId)
        },
        roundGroups() {
            let groups = JSON.parse(JSON.stringify(this.showRound.groups));
            for (let group of groups) {
                group.teams.sort(this.sortByName);
            }
            return groups.sort(this.sortByName);
        },
        submitDisabled() {
            if (this.scoreModalData.scores[0] && (this.scoreModalData.scores[0].score.score === null || this.scoreModalData.scores[0].score.score === "")) return true;
            if (this.scoreModalData.scores[1] && (this.scoreModalData.scores[1].score.score === null || this.scoreModalData.scores[1].score.score === "")) return true;
            return false;
        }
    },

    data() {
        return {
            round: null,
            scoreModalData: null,
            scoreModalLoading: false,
            loading: false,
            modal: {
                group:      null,
                title:      "",
                teams:      [],
                allTeams:   [],
                addTeam:    null,
            }
        };
    },

    methods: {
        sortByName(a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        },

        async handleUpdate(data) {
            if (data.id) {
                await new Promise((resolve) => {
                    this.$socket.emit('score.update', data, (response) => {
                        resolve(response);
                    });
                });
            } else {
                this.$socket.emit('score.create', data, () => {
                    // console.log(response);
                });
            }
        },

        getAllTeamsForModal(teams) {
            if (! this.$store.state.leaderboard.teams) {
                return [];
            }
            return this.$store.state.leaderboard.teams
                .filter((team) => teams.map(item => item.id).indexOf(team.id) === -1)
                .sort(this.sortByName)
            ;
        },

        async openEditModal(group) {
            this.modal.group    = group;
            this.modal.title    = `${group.name} - Teams bewerken`;
            this.modal.teams    = group.teams.sort(this.sortByName);
            this.modal.allTeams = this.getAllTeamsForModal(group.teams);
            this.modal.addTeam  = null;

            await this.$refs.myModal.open();
        },

        async addTeamToGroup(force = false) {
            if (this.loading && ! force) {
                return;
            }
            this.loading = true;
            if (! this.modal.addTeam.id && this.modal.addTeam.name) {
                const params = {
                    name:    this.modal.addTeam.name,
                    board:   sessionStorage.getItem("boardId"),
                    boardId: sessionStorage.getItem("boardId"),
                }

                this.$socket.emit('team.create', params, async (data) => {
                    this.modal.addTeam = data;
                    await this.addTeamToGroup(true);
                });
                return;
            }
            this.$socket.emit(
                'group.addTeam',
                {
                    boardId: sessionStorage.getItem('boardId'),
                    team:    this.modal.addTeam.id,
                    group:   this.modal.group.id
                },
                (data) => {
                    if (! data.errors) {
                        this.modal.addTeam  = null;
                        this.modal.teams    = data.data.teams;
                        this.modal.allTeams = this.getAllTeamsForModal(data.data.teams);
                    }
                }
            );
            this.loading = false;
            await this.$refs.myModal.close();
        },

        async removeTeamFromGroup(team) {
            this.$socket.emit(
                'group.removeTeam', 
                { 
                    boardId: sessionStorage.getItem('boardId'), 
                    team:    team,
                    group:   this.modal.group.id
                },
                (data) => {
                    if (! data.errors) {
                        this.modal.addTeam  = null,
                        this.modal.teams    = data.data.teams;
                        this.modal.allTeams = this.getAllTeamsForModal(data.data.teams);
                    }
                }
            );
        },

        async openScoreModal(game) {
            for (let score of game.scores) {
                if (! score.score.id) {
                    score.score.game = game.gameId;
                    score.score.team = score.team.id;
                }
            }
            this.scoreModalData = game;
            this.$refs.scoreModal.open();
            await this.$nextTick();
            this.$refs.modalScore[0].$el.focus();
            this.$refs.modalScore[0].$el.select();
        },

        async postScores() {
            if (this.submitDisabled) return false;

            this.scoreModalLoading = true;
            for (let score of this.scoreModalData.scores) {
                await this.handleUpdate(score.score);
            }
            this.$refs.scoreModal.close();
            this.scoreModalData = null;
            this.scoreModalLoading = false;
        },
        async focusNext(index) {
            const el = this.$refs.modalScore[index + 1];
            el.$el.focus();
            await this.$nextTick();
            el.$el.select();
        },
        createTeamOption(value) {
            if (this.modal.teams.map(item => item.name).includes(value)) {
                return;
            }
            if (
                this.modal.allTeams.map(item => item.name).includes(value) &&
                this.modal.allTeams.find(item => item.name).id !== 0
            ) {
                return;
            }
            let newOption = { id: 0, name: value };
            if (this.modal.allTeams.map(item => item.id).includes(0)) {
                const index = this.modal.allTeams.findIndex(item => item.id === 0);
                this.modal.allTeams[index] = newOption;
            } else {
                this.modal.allTeams.push(newOption);
            }
        },
    }
};
</script>
