import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import Scores from "@/pages/Scores.vue";
import Teams from "@/pages/Teams.vue";
import Rounds from "@/pages/Rounds.vue";
import Boards from "@/pages/Boards.vue";
import leaderboard from "@/pages/Leaderboard.vue";

const routes = [
  {
    path: "/leaderboard",
    component: leaderboard,
    children: [
      {
        path: ":id",
        name: "leaderboard",
        component: leaderboard,
      }
    ]
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "user",
        name: "User Profile",
        component: UserProfile,
      },
      {
        path: "teams",
        name: "Teams",
        component: Teams,
      },
      {
        path: "scores",
        name: "Scores",
        component: Scores,
      },
      {
        path: "rounds",
        name: "Rondes",
        component: Rounds,
      },
      {
        path: "boards",
        name: "Boards",
        component: Boards,
      },
    ],
  },
];

export default routes;
