<template>
    <div id="app">
      <router-view />
      <v-modal-target class="modal-target" name="modal-target" />
    </div>
</template>

<script>

import { VModalTarget } from "@arfedulov/vue2-modal";

export default {
    name: 'App',

    components: { VModalTarget },

    sockets: {
        connect: function () {
            // console.log('socket connected')
        },
        'leaderboard.update': function () {
            // console.log("Leaderboard update");
            this.getLeaderboard();
        },
        'leaderboard.setActiveRound': function (data) {
            // console.log("set active round");
            this.$store.commit('setActiveRound', data);
        },
        'leaderboard.refresh': function() {
            location.reload();
        },
        'leaderboard.setFontSize': function(data) {
            // this.fontSize = data.data;
            this.$store.commit('updateFontSize', data.data);
        },
        'leaderboard.updateShowTotalScore': function(data) {
            // this.fontSize = data.data;
            this.$store.commit('updateShowTotalScore', data.data);
        },
        'score.update': function (data) {
            // console.log("score update");
            this.$store.commit('updateScore', data);
        },
        'score.add': function (data) {
            // console.log("score add");
            this.$store.commit('addScore', data);
        },
        'group.update': function (data) {
            // console.log("group update");
            this.$store.commit('updateGroup', data);
        },
        'team.update': function (data) {
            // console.log("team update");
            this.$store.commit('updateTeam', data);
        },
    },

    async mounted() {
        if (sessionStorage.getItem("boardId")) {
            this.getLeaderboard();
        } else {
            this.$router.push("/boards");
        }
    },

    methods: {
        async getLeaderboard() {
            let relations = [
                "rounds",
                "teams",
                "activeRound",
                "rounds.groups",
                "rounds.groups.games",
                "rounds.groups.teams",
                "rounds.groups.games.scores",
                "rounds.groups.games.scores.team",
                "rounds.groups.games.teams",
                "rounds.groups.games.teams.persons"
            ];
            
            const apiUrl = process.env.VUE_APP_SOCKET_PORT === 80 || process.env.VUE_APP_SOCKET_PORT === 443
                ? process.env.VUE_APP_API_URL
                : `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_SOCKET_PORT}`
            ;

            if (this.$route.name === 'leaderboard') {
                console.log("Get leaderboard!!");
                const id = this.$route.params.id;
                if (id) {
                    let result = await this.axios.get(`${apiUrl}/api/board/${id}`, { params: { relations }});
                    this.$store.commit('updateLeaderboard', result.data);
                }
            } else if (this.$route.name !== 'boards') {
                const id = sessionStorage.getItem('boardId');
                console.log(id);
                if (id) {
                    let result = await this.axios.get(`${apiUrl}/api/board/${id}`, { params: { relations }});
                    this.$store.commit('updateLeaderboard', result.data);
                } else {
                    this.$router.push('boards');
                }
            }
        }
    }
}
</script>

<style>
   .v-modal__backdrop {
        background: #000 !important;
        opacity: 0.5 !important;
    }
</style>
